import format from 'date-fns/format'
import differenceInSeconds from 'date-fns/difference_in_seconds'
import differenceInDays from 'date-fns/difference_in_days'
import differenceInMinutes from 'date-fns/difference_in_minutes'
import differenceInHours from 'date-fns/difference_in_hours'
import { getExpConfig } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'

const getCountdownConfig = () => {
  const countdownFlag = getExpConfig(SPLIT_FEATURES.BROW_COUNTDOWN)
  const config = countdownFlag?.config
  let countdownConfig = { showCounter: false }
  if (config) {
    countdownConfig = {
      ...JSON.parse(config),
      showCounter: countdownFlag?.treatment === 'on',
    }
  }
  return countdownConfig
}

const getUtcDate = inputDate => {
  const utcDate = inputDate.getTime() + inputDate.getTimezoneOffset()
  return new Date(utcDate + 8)
}

const hasCountdownStarted = (currentDate, startDate) => {
  return differenceInSeconds(currentDate, startDate) > 0
}

const hasCountdownEnded = (currentDate, endDate) => {
  return differenceInSeconds(endDate, currentDate) < 0
}

const getRemainingTime = (startDate, endDate) => {
  const utcCurrentDate = getUtcDate(new Date())
  const utcStartDate = getUtcDate(startDate)
  const hasStarted = hasCountdownStarted(utcCurrentDate, utcStartDate)
  const utcEndDate = getUtcDate(endDate)
  const hasEnded = hasCountdownEnded(utcCurrentDate, utcEndDate)
  let daysRemaining = '00'
  let hoursRemaining = '00'
  let minutesRemaining = '00'
  let secondsRemaining = '00'

  if (!hasStarted) {
    return {
      daysRemaining: '',
      hoursRemaining: '',
      minutesRemaining: '',
      secondsRemaining: '',
    }
  } else if (hasStarted && hasEnded) {
    return {
      daysRemaining: '00',
      hoursRemaining: '00',
      minutesRemaining: '00',
      secondsRemaining: '00',
    }
  }

  let refStartDate = utcStartDate
  if (hasStarted && !hasEnded) {
    refStartDate = utcCurrentDate
  }
  daysRemaining = differenceInDays(utcEndDate, refStartDate).toString()
  hoursRemaining = (differenceInHours(utcEndDate, refStartDate) % 24)
    .toString()
    .padStart(2, '0')
  minutesRemaining = (differenceInMinutes(utcEndDate, refStartDate) % 60)
    .toString()
    .padStart(2, '0')
  secondsRemaining = (differenceInSeconds(utcEndDate, refStartDate) % 60)
    .toString()
    .padStart(2, '0')

  return {
    daysRemaining,
    hoursRemaining,
    minutesRemaining,
    secondsRemaining,
  }
}

const hideDaysRemaining = (startDate, endDate) => {
  const utcCurrentDate = getUtcDate(new Date())
  const utcStartDate = getUtcDate(startDate)
  const utcEndDate = getUtcDate(endDate)
  const startedToday =
    format(utcStartDate, 'DD-MM-YYYY') === format(utcCurrentDate, 'DD-MM-YYYY')
  const endingToday =
    format(utcStartDate, 'DD-MM-YYYY') === format(utcEndDate, 'DD-MM-YYYY')

  return startedToday && endingToday
}

export { getCountdownConfig, getRemainingTime, hideDaysRemaining }
