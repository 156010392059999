import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import NoImg from '../icons/NoImg'
import Text from './../Text'
import { SCREEN_SIZE } from '../../lib/Media'

export const CompactProductWrapper = styled.div`
  position: relative;
  margin: 0 0.5rem 0.5rem 0;

  &:hover {
    box-shadow: 2px 2px 6px 0 rgba(105, 105, 105, 0.2);
    border-radius: 4px;
  }
  ${SCREEN_SIZE.From.Tablet} {
    margin: 0 1rem 0.5rem 0;
  }
`

export const StyledContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  backdrop-filter: blur(2.8px);
  background-color: #ffffff;
  width: ${props =>
    props.variant === 'checkout' || props.variant === 'wishlist'
      ? '100%'
      : '20rem'};
  display: flex;
  align-items: flex-start;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.variant === 'checkout' || props.variant === 'wishlist'
      ? '#eaeaea'
      : props.inCart
      ? defaultTheme.ColorBaseBrandPrimary500
      : '#eaeaea'};
  padding: 0.75rem 0.75rem 0.75rem 0.5rem;
  min-height: ${props =>
    props.variant === 'checkout' || props.variant === 'wishlist'
      ? props.variant === 'checkout'
        ? '8rem'
        : '7.5rem'
      : '10.625rem'};
  margin-bottom: ${props =>
    props.variant === 'checkout' || props.variant === 'wishlist'
      ? '0.5rem'
      : '0'};
  ${SCREEN_SIZE.From.MobileL} {
    width: ${props =>
      props.variant === 'checkout' || props.variant === 'wishlist'
        ? '100%'
        : '21.5rem'};
  }
`

export const StyledText = styled(Text)`
  ${props =>
    (props.variant === 'checkout' || props.variant === 'wishlist') &&
    'margin-right: 2rem'};
  color: ${props => props.color};
  text-transform: capitalize;
  display: inline-block;
  height: 2.5rem;
  overflow: hidden;
`

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  justify-content: space-between;
  flex: 1;
`
export const StyledSaveButton = styled.button`
  right: 0.65rem;
  position: absolute;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`
export const StyledDeleteButton = styled.button`
  border: none;
  background-color: white;
  color: #696969;
  position: absolute;
  right: 0.3rem;
  cursor: pointer;
`
export const StyledA = styled.a`
  text-decoration: none;
  color: currentColor;
`

export const StyledInlineBlock = styled.div`
  display: inline-flex;
  align-items: center;
  > span {
    margin-right: 0.3rem;
    position: ${props =>
      props.variant === 'checkout' ? 'absolute' : 'static'};
    right: ${props => (props.variant === 'checkout' ? '0.3rem' : 'unset')};
    bottom: 0.9rem;
  }
`
export const StyledNoImageIcon = styled(NoImg)`
  width: 5rem;
  height: 5rem;
  display: inline-block;
  padding: 0.5rem;
`
export const StyledDiscount = styled(Text)`
  margin-left: 0.25rem;
`
export const StyledList = styled.div`
  display: flex;
  margin-top: 0.25rem;
`
export const Seperator = styled.span`
  margin: 0 2px;
`
export const CartCounterWrapper = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 0;
  > div {
    box-shadow: none;
  }

  ${SCREEN_SIZE.From.Tablet} {
    right: 0.3rem;
    bottom: 0.25rem;
  }
`

const borderRadiusSet = {
  single: '3px 0 3px 0',
  first: '3px 0 0 0',
  last: '0 0 3px 0',
}

export const PwpFlag = styled(Text)`
  display: inline-flex;
  margin: 0 0.125rem 0 0;
  padding: 0.125rem;
  background: ${props =>
    props.bgcolour
      ? `${props.bgcolour}`
      : defaultTheme.ColorBaseBrandSecondary500};
  border-radius: ${props =>
    props.borderstyle
      ? borderRadiusSet[props.borderstyle]
      : borderRadiusSet.single};
`

export const StyledTagWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`

export const ImageContainer = styled.div`
  width: 5rem;
  height: 5rem;
  position: relative;
`

export const MarketplaceItemLabelListContainer = styled.div`
  margin: 0.375rem 0 0 0;
`
