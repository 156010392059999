import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'
import { SvgClock } from '@ntuctech/devex-tangram/Icon'
import { SCREEN_SIZE } from '../../lib/Media'

function getSwimlaneCountdownSplit() {
  const { isEnabled, configuration } = getConfigFor(
    SPLIT_FEATURES.SWIMLANE_COUNTDOWN_ENABLED
  )
  return { isEnabled, configuration }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.625rem 1.25rem;
  align-items: center;
  margin-bottom: ${({ rounded }) => (rounded ? 0 : '-1.3125rem')};
`

const OngoingContainer = styled(Container)`
  background-color: #2070ef;
  display: flex;
  flex-direction: row;
  border-radius: ${({ rounded }) => (rounded ? '0.25rem' : 0)};
  margin-bottom: 1rem;
  padding: 0.625rem 1.25rem;
  align-items: center;
  justify-content: center;

  ${SCREEN_SIZE.From.Desktop} {
    padding: 0.375rem 1.25rem;
  }
`

const EndedContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  border-radius: ${({ rounded }) => (rounded ? '0.25rem' : 0)};
  margin-bottom: 1rem;
  align-items: center;
  background-color: #999999;
  justify-content: start;
  padding: 0.5rem 1rem;

  ${SCREEN_SIZE.From.Desktop} {
    padding: 0.25rem 1rem;
  }
`

const Title = styled.div`
  color: white;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-right: 1rem;
`

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: white;
`

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const CountdownItemContainer = styled.div`
  display: flex;
  background-color: white;
  margin-right: 0.25rem;
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;
  width: 2.8125rem;
  height: 2.25rem;

  &:last-child {
    margin-right: 0;
  }
`

const TimeComponentValue = styled.div`
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: #333333;
`

const TimeComponentUnit = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #333333;
  margin-left: 0.125rem;
`

const Icon = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
`

const CountdownItem = ({ value, unit }) => {
  return (
    <CountdownItemContainer>
      <TimeComponentValue>{value}</TimeComponentValue>
      <TimeComponentUnit>{unit}</TimeComponentUnit>
    </CountdownItemContainer>
  )
}

const formatTime = seconds => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  return {
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  }
}

const PromoOngoingCountdown = ({ label, timeRemaining, rounded }) => {
  const { hours, minutes, seconds } = formatTime(timeRemaining)

  return (
    <OngoingContainer data-testid="promo-ongoing-banner" rounded={rounded}>
      <Title>{label}</Title>
      <CountdownContainer>
        <CountdownItem value={hours} unit="h" />
        <CountdownItem value={minutes} unit="m" />
        <CountdownItem value={seconds} unit="s" />
      </CountdownContainer>
    </OngoingContainer>
  )
}

const PromoEndedCountdown = ({ rounded, configuration }) => {
  return (
    <EndedContainer data-testid="promo-ended-banner" rounded={rounded}>
      <Icon>
        <SvgClock size="l" color="white" />
      </Icon>
      <div>
        <Title>{configuration?.endedTitle || ' Promotion ended'}</Title>
        <Subtitle>
          {configuration?.endedSubtitle || 'Discount is no longer valid'}
        </Subtitle>
      </div>
    </EndedContainer>
  )
}

export const useCountdown = (initialTime, disabled) => {
  const [timeRemaining, setTimeRemaining] = useState(initialTime)

  useEffect(() => {
    if (disabled) {
      return
    }
    const interval = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime <= 0) {
          clearInterval(interval)
          return 0
        }
        return prevTime - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [disabled])

  return timeRemaining
}

export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  return isMounted
}

const SwimlaneCountdown = ({ swimlaneTitle, rounded = true }) => {
  const { isEnabled, configuration } = getSwimlaneCountdownSplit()

  const isMounted = useIsMounted()

  const ref = React.useRef({
    isBeforeStartDate: configuration?.startTimestamp
      ? new Date().getTime() < configuration.startTimestamp
      : false,
    isAfterEndDate: configuration?.endTimestamp
      ? new Date().getTime() > configuration.endTimestamp
      : false,
  })

  const isTargetSwimlane = Boolean(
    configuration?.swimlaneTitle &&
      swimlaneTitle === configuration?.swimlaneTitle
  )

  const countdownEnabled =
    isEnabled &&
    isTargetSwimlane &&
    !ref.current.isBeforeStartDate &&
    !ref.current.isAfterEndDate

  const timeLeftInMilliseconds = countdownEnabled
    ? configuration?.endTimestamp - new Date().getTime()
    : 0

  const timeLeftInSeconds = Math.round(timeLeftInMilliseconds / 1000)

  const timeRemaining = useCountdown(timeLeftInSeconds, !countdownEnabled)

  if (!countdownEnabled || !isMounted) {
    return null
  }

  return timeRemaining > 0 ? (
    <PromoOngoingCountdown
      rounded={rounded}
      label={configuration?.ongoingText}
      timeRemaining={timeRemaining}
    />
  ) : (
    <PromoEndedCountdown rounded={rounded} configuration={configuration} />
  )
}

export default SwimlaneCountdown
