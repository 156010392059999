import React from 'react'
import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import Text from './../Text'

const StyledDel = styled(Text).attrs({
  'aria-label': 'Original price',
})`
  color: #6c6c6c;
  text-decoration: line-through;
`

const StyledText = styled(Text)`
  color: ${defaultTheme.ColorBaseMineShaft700};
`

class Price extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      amount,
      discount,
      forceDigits,
      outdated,
      size,
      customClassName,
    } = this.props

    const parsedAmount = amount ? parseFloat(amount) : 0
    const parsedDiscount = discount ? parseFloat(discount) : 0

    const hasDiscount = parsedDiscount > 0
    const currentAmount = hasDiscount
      ? parsedAmount - parsedDiscount
      : parsedAmount

    const formattedPrice =
      currentAmount === 0 && !forceDigits
        ? 'Free'
        : `$${Math.abs(currentAmount).toFixed(2)}`

    const isNegative = currentAmount < 0
    const RenderAsElement = outdated ? StyledDel : StyledText
    return (
      <RenderAsElement
        size={size}
        className={customClassName ? customClassName : null}
      >
        {isNegative && '-'}
        {formattedPrice}
      </RenderAsElement>
    )
  }
}

Price.defaultProps = {}

export default Price
