import React from 'react'
import styled from 'styled-components'
import ButtonOutlined from '@ntuctech/devex-tangram/Button/ButtonOutlined'
import ProductCollection from '../ProductCollection/ProductCollection'
import ImageSlideShow from '../ImageSlideShow/ImageSlideShow'
import { loadLayouts } from '../LoadLayout'
import { SPLIT_FEATURES } from '../../utils/constants'
import { getConfigFor } from '../../utils/configService'
import { SCREEN_SIZE } from '../../lib/Media'
import Link from 'next/link'

const Container = styled.div`
  background-color: white;
  padding: 0 1rem 0.6875rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;

  ${SCREEN_SIZE.From.Tablet} {
    width: calc((100% - 1rem) / 2);
    margin-bottom: 2.5rem;

    &:nth-child(2n) {
      margin-left: 1rem;
    }
  }
`

const Header = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.5rem;
`

const HeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;

  a {
    text-decoration: none;
  }
`

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #333333;
`

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #666666;
`

const BannerContainer = styled.div`
  padding-top: 1rem;
`

const SwimlaneContainer = styled.div`
  margin-top: 1rem;
`

const CustomButtonOutlined = styled(ButtonOutlined)`
  margin: 0;
`

const ProductCollectionBannerListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${SCREEN_SIZE.From.Tablet} {
    flex-direction: row;
  }
`

export const ProductCollectionWithBannerList = props => {
  const {
    layouts,
    additionalConfig,
    loadComponentBefore,
    componentToLoad,
    additionalProps,
    preLoadFirstImage,
  } = props

  const { isEnabled: isPromoSwimlaneWithBannerEnabled } = getConfigFor(
    SPLIT_FEATURES.SWIMLANE_WITH_BANNER_ENABLED
  )

  if (!isPromoSwimlaneWithBannerEnabled || !layouts.length) {
    return null
  }

  return (
    <ProductCollectionBannerListContainer data-testid="product-collection-with-banner-list">
      {loadLayouts(
        layouts,
        additionalConfig,
        loadComponentBefore,
        componentToLoad,
        additionalProps,
        preLoadFirstImage
      )}
    </ProductCollectionBannerListContainer>
  )
}

export const SwimlaneHeader = ({
  title,
  subtitle,
  seeAllHref,
  onSeeAllClick,
}) => {
  return (
    <Header>
      <HeaderTop>
        <Title>{title}</Title>
        {Boolean(seeAllHref) && (
          <Link href={seeAllHref} prefetch={false}>
            <CustomButtonOutlined onClick={onSeeAllClick}>
              See all
            </CustomButtonOutlined>
          </Link>
        )}
      </HeaderTop>
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    </Header>
  )
}

const ProductCollectionWithBanner = props => {
  const { data, config } = props

  const bannerProps = {
    ...props,
    data: {
      images: data.banners,
      ...data,
    },
    config: {
      images: data.banners,
      ...config,
    },
  }

  if (!data.collection?.product?.length) {
    return null
  }

  return (
    <Container>
      {Boolean(data?.banners?.length) && (
        <BannerContainer data-testid="swimlane-banner">
          <ImageSlideShow {...bannerProps} withProductCollection />
        </BannerContainer>
      )}
      <SwimlaneContainer>
        <ProductCollection {...props} withBanner />
      </SwimlaneContainer>
    </Container>
  )
}

export default ProductCollectionWithBanner
