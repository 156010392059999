import styled from 'styled-components'

//@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) is IE specific hack
const TruncatedText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${({ clamp }) => clamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-height: calc(0.75rem * ${({ clamp }) => clamp});
    line-height: 1.1;
  }
  margin: auto 0;
`
export default TruncatedText
