import React, { Fragment } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import GlobalContext from '../GlobalContext'
import throttle from 'lodash/throttle'
import {
  CompactProductWrapper,
  StyledContainer,
  StyledText,
  StyledInfo,
  StyledSaveButton,
  StyledDeleteButton,
  StyledA,
  StyledInlineBlock,
  StyledNoImageIcon,
  StyledDiscount,
  CartCounterWrapper,
  PwpFlag,
  ImageContainer,
  StyledTagWrapper,
  MarketplaceItemLabelListContainer,
} from './styles'
import Price from './../Price'
import Text from './../Text'
import CartCounter, { OutOfStockInProductCards } from './../CartCounter'
import { CartConsumer } from './../CartProvider'
import { WishlistConsumer } from './../WishlistProvider'
import { EventTrackingConsumer } from '../EventTrackingProvider'
import MarketplaceItemLabelList from '../MarketplaceItemLabelList/MarketplaceItemLabelList'
const ShoppingListPopup = dynamic(() =>
  import('../ShoppingList/ShoppingListPopup/ShoppingListPopup')
)
import FavouriteInactiveIcon from '../icons/FavouriteInactive'
import FavouriteActiveIcon from '../icons/FavouriteActive'
import DeleteIcon from '../icons/Delete'
import { getHandlingTimeDisplayText, isBoughtBefore } from '../../utils/Utils'
import { IdleQueue } from '../../lib/idlize/IdleQueue'
const queue = new IdleQueue()
import {
  fetchCategory,
  getNCheckFn as getNCheck,
  GTM_ENV_VAR as GEV,
  PAGE_TYPES,
} from '../../lib/eventTrackerFunction'
import getPwpOffer from '../../lib/offer'
import ProgressiveImage from '../ProgressiveImage'
import { Router } from '../../routes'
import { truncateText } from '../../lib/stringHelperFunctions'
import {
  isOutOfStock,
  OUT_OF_STOCK,
  IN_STOCK,
} from '../../lib/getInventoryStatus'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider/CheckoutAddressProvider'
import {
  getProductImpressionsTrackingData,
  getProductClickTrackingData,
  getSplitProductTrackingData,
} from '../../lib/getProductImpressionsTrackingData'
import {
  isCitrusProductTrackingEnabled,
  trackCitrusAdProductClick,
} from '../../lib/trackCitrus'
import compact from 'lodash/compact'
import {
  AMP_EVENT_NAME as AEN,
  formatCtaLocation,
  getPageTypeByUrl,
} from '../../lib/amplitude'
import {
  getCtaLocationMeta,
  getPreviousCtaLocationMeta,
  setCtaLocation,
  setCtaSublocation,
  setPreviousPageMeta,
} from '../../lib/utils'
import { getAddAndRemoveFromCartEcommerceEvent } from '../ProductCard/utils'
import ProductCampaignLabel, {
  CampaignLabelContainer,
} from '../ProductCampaignLabel/ProductCampaignLabel'
import ProductCardMetaData from '../ProductCardMetaData/ProductCardMetaData'
import { getAddRemoveCartMetaFromDelta } from '../../utils/tracking/trackingUtils'
import {
  trackingAddToCartForDY,
  trackingProductClickDY,
} from '../../utils/DyUtils'

class CompactProduct extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showList: false,
      notDefaultClick: false,
    }
    this.handleShowList = this.handleShowList.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.productDetailsCardEvent = this.productDetailsCardEvent.bind(this)
    this.productCartAction = this.productCartAction.bind(this)
    this.handleTrackOnClick = throttle(
      this.handleTrackOnClick.bind(this),
      2000,
      { trailing: false }
    )
    this.createObserver = this.createObserver.bind(this)

    this.ref = React.createRef()

    this.productRef = null

    this.setProductRef = element => {
      this.productRef = element
    }
  }

  createObserver(params = {}) {
    if (this.props.createObserver) {
      const productData = getProductImpressionsTrackingData({
        productObj: this.props,
        ga4ProductListName: this.props.ga4ProductListName,
      })
      const options = {
        productRef: this.productRef,
        productTrackObj: productData,
        splitTrackObj: getSplitProductTrackingData({
          eventType: 'splitProductImpression',
          productObj: this.props,
        }),
        callAction: 'onLoad',
      }
      if (params?.action === 'afterUpdate') {
        options.callAction = 'afterUpdate'
      }
      this.props.createObserver(options)
    }
  }

  componentDidMount() {
    queue.pushTask(() => {
      this.createObserver()
    })
    truncateText(this.ref.current)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.details.id !== this.props.details.id ||
      (prevProps.details.id === this.props.details.id &&
        (prevProps.checkoutAddress.storeId !==
          this.props.checkoutAddress.storeId ||
          prevProps.productListName !== this.props.productListName))
    ) {
      this.createObserver({ action: 'afterUpdate' })
    }
  }

  componentWillUnmount() {
    this.productRef = null
  }

  handleTrackOnClick(e) {
    if (e.button === 0) {
      e.preventDefault()
    }
    const {
      details,
      track,
      trackSplit,
      ctaSublocation,
      ctaLocation,
      productPosition,
    } = this.props
    if (this.props.onProductImpressions) {
      this.props.onProductImpressions({
        forceTrack: true,
        eventType: 'abandon',
      })
    }
    const eventData = getProductClickTrackingData({ productObj: this.props })
    setCtaSublocation(ctaSublocation)
    setCtaLocation(ctaLocation)
    track(eventData)

    setPreviousPageMeta({
      productPosition,
    })
    // track for dy
    const dyPayload = trackingProductClickDY(details, this.props?.metaData)
    track(dyPayload)

    //tracking ecomm product click for split
    const splitEventData = getSplitProductTrackingData({
      eventType: 'splitProductClick',
      productObj: this.props,
    })
    trackSplit([splitEventData])

    if (e.button === 0) {
      Router.push(
        details?.adId
          ? `/productDetails?slug=${details.slug}&adId=${details.adId}`
          : `/productDetails?slug=${details.slug}`,
        `/product/${details.slug}`
      )
    } else if ([1, 2].includes(e.button)) {
      this.setState({ notDefaultClick: true })
    }
  }

  handleShowList(event, value) {
    if (event) {
      event.stopPropagation()
      event.preventDefault()

      const { isLoggedIn, details } = this.props

      const redirectPath = Router && Router.router ? Router.router.asPath : '/'
      if (!isLoggedIn) {
        window.location.href = `/login?redirect=${encodeURIComponent(
          redirectPath
        )}`
        return
      }

      const isAddToWishlist = !this.props.wishlistConsumer.includes(details)
      // Event tracking for mark item as favourite or save to wishlist.
      this.productDetailsCardEvent({
        eventAction: 'Shortlist',
        eventCat: 'Product-Core',
        value,
        amplitudeEventName: isAddToWishlist
          ? AEN.PRODUCT_ADDED_TO_LIST
          : AEN.PRODUCT_REMOVED_FROM_LIST,
        delta: isAddToWishlist ? 1 : -1,
      })

      this.setState({
        clientX: event.clientX,
        clientY: event.clientY,
      })
    }
    this.setState(prevState => {
      return {
        showList: !prevState.showList,
      }
    })
  }

  handleDelete(event) {
    event.preventDefault()
    this.props.onDelete({ product: this.props.details })
  }

  getIsItemOutOfStock() {
    const {
      details,
      unlimitedStock,
      bulkOrderThreshold,
      storeBulkOrderThreshold,
      checkoutAddress,
      countOf,
    } = this.props
    const storeSpecific = details.storeSpecificData[0]
    return isOutOfStock({
      value: countOf(details),
      stock: storeSpecific.stock,
      unlimitedStock,
      bulkOrderThreshold,
      storeBulkOrderThreshold,
      checkoutAddress,
    })
  }

  productDetailsCardEvent({
    eventAction,
    eventCat,
    value,
    amplitudeEventName,
    delta,
  }) {
    const {
      details,
      itemCount,
      track,
      loc,
      ctaSublocation,
      pageType,
      checkoutAddress,
      remoteConfig,
      productPosition,
      ga4ProductListName,
    } = this.props
    const productInfo = details
    const storeSpecific = details.storeSpecificData[0]
    const categoryList = fetchCategory(productInfo.primaryCategory)
    const offerPrice = (storeSpecific.mrp - storeSpecific.discount).toFixed(2)
    const offersToTrack = details.offers && details.offers.slice(0, 2)
    const promoText =
      offersToTrack && offersToTrack.map(offer => offer.description).join('|')
    const itemIsOutOfStock = this.getIsItemOutOfStock()

    const isProductBoughtBefore =
      productInfo?.pastPurchases && isBoughtBefore(productInfo?.pastPurchases)
        ? 'yes'
        : 'no'

    const event = {
      [GEV.EVENT_CAT]: eventCat,
      [GEV.EVENT_ACTION]: eventAction,
      [GEV.EVENT_LABEL]: `Loc=${loc}`,
      [GEV.PROD_ID]: getNCheck(productInfo, 'clientItemId', '').toString(),
      [GEV.PRODUCT_ID_ZOP]: getNCheck(productInfo, 'id', '').toString(),
      [GEV.PROD_NAME]: getNCheck(productInfo, 'name', ''),
      [GEV.PROD_CUR]: getNCheck(storeSpecific, 'currency.name', ''),
      [GEV.PROD_QUANT]: value ? value.toString() : '',
      [GEV.PROD_BRAND]: getNCheck(productInfo, 'brand.name', ''),
      [GEV.RETAIL_PRICE]: parseFloat(getNCheck(storeSpecific, 'mrp', ''))
        .toFixed(2)
        .toString(),
      [GEV.OFFER_PRICE]: offerPrice ? offerPrice.toString() : '',
      [GEV.PROD_CAT]: 'Retail',
      [GEV.INVTRY_COUNT]: getNCheck(storeSpecific, 'stock', '').toString(),
      [GEV.PAGE_TYPE]: pageType ? pageType : PAGE_TYPES['SEARCH_RESULT'],
      [GEV.SEARCH_RES_COUNT]: itemCount ? itemCount.toString() : undefined, // No of search results
      [GEV.PROMO_TEXT]: promoText,
      [GEV.INVENTORY_STATUS]: itemIsOutOfStock ? OUT_OF_STOCK : IN_STOCK,
      [GEV.AMPLITUDE_EVENT_NAME]: amplitudeEventName,
      [GEV.PRODUCT_RATING]: productInfo?.reviews?.statistics?.average,
      [GEV.PROMOTION_COUNT]: productInfo?.offers?.length || 0,
      [GEV.PROMOTION_TYPES]: compact(
        productInfo?.offers?.map(offer => offer?.metaData?.sapReference) || []
      ),
      [GEV.PROMOTION_DESCRIPTIONS]: compact(
        productInfo?.offers?.map(offer => offer.description) || []
      ),
      [GEV.AMPLITUDE_CTA_SUBLOCATION]: ctaSublocation,
      [GEV.AMPLITUDE_CTA_LOCATION]: {
        pageName: getPageTypeByUrl(),
        pageMeta: getCtaLocationMeta() || [],
      },
      [GEV.AMPLITUDE_2CLICK_CALL_TO_ACTION_LOCATION]: {
        pageName: getPageTypeByUrl(sessionStorage.getItem('previousRoute')),
        pageMeta: getPreviousCtaLocationMeta(),
      },
      [GEV.AMPLITUDE_PRODUCT_QUANTITY]: delta,
      [GEV.AMPLITUDE_IS_PAST_PURCHASE]: isProductBoughtBefore,
      ...getAddAndRemoveFromCartEcommerceEvent({
        productPosition,
        productInfo,
        eventAction,
        categoryList,
        storeSpecific,
        itemIsOutOfStock,
        remoteConfig,
        checkoutAddress,
        loc,
        details,
        ga4ProductListName,
        delta,
      }),
    }
    /* istanbul ignore if  */
    if (productPosition) {
      event[[GEV.PRODUCT_POSITION]] = productPosition
    }
    /* istanbul ignore if  */
    if (['AddToCart', 'RemoveFromCart'].includes(eventAction)) {
      event[[GEV.DIMENSION97]] =
        isCitrusProductTrackingEnabled(this.props.remoteConfig) &&
        productInfo?.adId
          ? `ct_${productInfo?.adId}`
          : ''
      event[[GEV.DIMENSION106]] = details?.track || ''

      event[GEV.ASSET_TYPE] = 'fpon'
      event[GEV.SNIPPET_VERSION] = 'ga4'
      event[GEV.SCREEN_NAME_GA4] = formatCtaLocation(
        event[GEV.AMPLITUDE_CTA_LOCATION],
        'grocery-online'
      )
      if (eventAction === 'AddToCart') {
        event[GEV.EVENT_CAT] = 'ecommerce-engagement'
        event[GEV.EVENT_ACTION] = event[GEV.EVENT_ACTION].toLowerCase()
        event[GEV.IS_LOWER_CASE_LOGGED_IN_STATUS] = true
      }
    }

    if (eventAction === 'AddToCart') {
      const dyPayload = trackingAddToCartForDY(
        details,
        this.props?.cartItems,
        delta,
        this.props?.metaData
      )
      track({ ...dyPayload })
    }

    if (productInfo.metaData && productInfo.metaData['Storage Information']) {
      event[[GEV.STORAGE]] = productInfo.metaData['Storage Information']
    }
    const keys = Object.keys(categoryList)
    keys.forEach(item => {
      event[item] = categoryList[item]
    })

    track(event)
  }

  productCartAction(delta) {
    const { details, cartItems, remoteConfig } = this.props
    const adIds = cartItems?.[details.id]?.adIds || []
    if (delta >= 1) {
      /* istanbul ignore next */
      trackCitrusAdProductClick(adIds, remoteConfig)
    }

    this.productDetailsCardEvent({
      ...getAddRemoveCartMetaFromDelta(delta),
      value: delta,
      delta,
    })
  }

  render() {
    const { details, onSave, onDelete, variant, className, checkoutAddress } =
      this.props
    const {
      name,
      description,
      images,
      id,
      slug,
      metaData,
      bulkOrderThreshold,
      offers,
      tagIds,
      stockOverride,
      campaignLabelImage,
    } = details
    const { handlingTimeDisplayText, handlingTimeTrackingText, deltime } =
      getHandlingTimeDisplayText(
        details,
        this.props.checkoutAddress?.storeId,
        true
      )
    const itemIsOutOfStock = this.getIsItemOutOfStock()

    const storeBulkOrderThreshold =
      (stockOverride && stockOverride.storeBulkOrderThreshold) || 0

    const maxPurchasableStock =
      (stockOverride && stockOverride.maxPurchasableStock) || Infinity

    let storeSpecificData = []
    if (checkoutAddress && checkoutAddress.storeId) {
      storeSpecificData =
        details.storeSpecificData &&
        details.storeSpecificData.filter(
          item => item.storeId === checkoutAddress.storeId
        )

      if (!storeSpecificData || storeSpecificData.length === 0) {
        storeSpecificData = details.storeSpecificData
      }
    } else {
      storeSpecificData = details.storeSpecificData
    }

    if (storeSpecificData && storeSpecificData.length === 0) {
      storeSpecificData = [
        { ...details.storeSpecificData[0], stock: 0, unlimitedStock: false },
      ]
    }

    if (!storeSpecificData || storeSpecificData.length === 0) {
      return null
    }
    const price = +(
      storeSpecificData &&
      storeSpecificData.length > 0 &&
      storeSpecificData[0].mrp
    )
    let discount =
      storeSpecificData &&
      storeSpecificData.length > 0 &&
      storeSpecificData[0].discount

    const unlimitedStock =
      storeSpecificData &&
      storeSpecificData.length > 0 &&
      storeSpecificData[0].unlimitedStock

    const { clientX, clientY } = this.state

    const { isPwpApplicable, pwpDiscount } = getPwpOffer(offers, price)

    if (pwpDiscount) {
      discount = Number(pwpDiscount || 0)
    }

    const hasDiscount = discount > 0
    const hasOffers = Boolean(offers && offers.length)
    const pdpBrowerLink = this.state.notDefaultClick
      ? details?.adId
        ? `/product/${slug}?adId=${details.adId}`
        : `/product/${slug}`
      : `/product/${slug}`

    const shouldShowDiscount = hasOffers && hasDiscount

    return (
      <CompactProductWrapper
        ref={this.setProductRef}
        data-refid={`${checkoutAddress?.clientId}-${details.clientItemId}`}
        data-impressiontype={this.props.impressionType}
      >
        <Link
          href={`/productDetails?slug=${slug}`}
          as={pdpBrowerLink}
          prefetch={false}
          passHref
          legacyBehavior
        >
          <StyledA>
            <StyledContainer
              inCart={this.props.includes({ id })}
              data-testid="compact-product"
              variant={variant}
              className={className}
              onClick={this.handleTrackOnClick}
            >
              <StyledTagWrapper>
                {isPwpApplicable && (
                  <PwpFlag
                    size="small"
                    weight="bold"
                    color="#ffffff"
                    borderstyle={'single'}
                  >
                    PWP
                  </PwpFlag>
                )}
                {shouldShowDiscount && !isPwpApplicable && (
                  <PwpFlag
                    size="small"
                    weight="bold"
                    color="#ffffff"
                    borderstyle={'single'}
                  >
                    Save {`$${Math.abs(discount).toFixed(2)}`}
                  </PwpFlag>
                )}
                {!shouldShowDiscount && !isPwpApplicable && hasOffers && (
                  <PwpFlag
                    size="small"
                    weight="bold"
                    color="#ffffff"
                    borderstyle={'single'}
                  >
                    Offer
                  </PwpFlag>
                )}
              </StyledTagWrapper>
              {images && images.length > 0 ? (
                <ImageContainer data-testid="compact-product-image">
                  <ProgressiveImage
                    alt={description || 'product-image'}
                    src={
                      typeof images[0] === 'string' && `${images[0]}?w=320&q=60`
                    }
                    imageQuality={60}
                    originalSrc={`${images[0]}?w=320&q=60`}
                    objectFitContain={true}
                    unoptimized={true}
                  />
                </ImageContainer>
              ) : (
                <StyledNoImageIcon />
              )}
              {price > 0 && onSave && (
                <Fragment>
                  <StyledSaveButton
                    onClick={event =>
                      this.handleShowList(event, this.props.countOf(details))
                    }
                  >
                    {this.props.wishlistConsumer.includes(details) ? (
                      <FavouriteActiveIcon
                        height="24"
                        width="24"
                        title="favourited"
                        color="#1557bf"
                      />
                    ) : (
                      <FavouriteInactiveIcon
                        height="24"
                        width="24"
                        title="favourite"
                        color={defaultTheme.ColorBaseBrandPrimary500}
                      />
                    )}
                  </StyledSaveButton>
                  {this.state.showList && (
                    <ShoppingListPopup
                      details={details}
                      position={{ clientX, clientY }}
                      isOpen={this.state.showList}
                      onClose={() => this.setState({ showList: false })}
                    />
                  )}
                </Fragment>
              )}
              {onDelete && (
                <StyledDeleteButton onClick={this.handleDelete}>
                  <DeleteIcon height="24" width="24" title="bin" />
                </StyledDeleteButton>
              )}
              <StyledInfo>
                <StyledInlineBlock>
                  {variant !== 'checkout' && (
                    <Text size="large" weight="black">
                      <Price amount={price} discount={discount} />
                    </Text>
                  )}
                  {variant !== 'checkout' && hasDiscount && price > 0 && (
                    <StyledDiscount size="small">
                      <Price outdated amount={price} />
                    </StyledDiscount>
                  )}
                  {!shouldShowDiscount &&
                    variant !== 'checkout' &&
                    hasDiscount &&
                    price > 0 && (
                      <StyledDiscount
                        size="small"
                        color="#ea6100"
                        weight="bold"
                      >
                        Save <Price amount={discount} />
                      </StyledDiscount>
                    )}
                </StyledInlineBlock>
                <StyledText
                  size="medium"
                  weight="regular"
                  variant={variant}
                  color={defaultTheme.ColorBaseMineShaft700}
                  ref={this.ref}
                >
                  <CampaignLabelContainer>
                    <ProductCampaignLabel
                      tagIds={tagIds}
                      campaignLabelImage={campaignLabelImage}
                    />
                    {name}
                  </CampaignLabelContainer>
                </StyledText>
                <ProductCardMetaData metaData={metaData} />
                <MarketplaceItemLabelListContainer>
                  <MarketplaceItemLabelList
                    deltime={deltime}
                    itemIsOutOfStock={itemIsOutOfStock}
                  />
                </MarketplaceItemLabelListContainer>
              </StyledInfo>

              <CartCounterWrapper value={this.props.countOf(details)}>
                <CartCounter
                  price={price}
                  details={details}
                  value={this.props.countOf(details)}
                  onValueChange={({ delta }) => {
                    this.props.update(
                      details,
                      delta,
                      () => {},
                      (_, totalDelta) => this.productCartAction(totalDelta)
                    )
                  }}
                  variant={variant}
                  stock={storeSpecificData[0] && storeSpecificData[0].stock}
                  unlimitedStock={unlimitedStock}
                  bulkOrderThreshold={bulkOrderThreshold}
                  storeBulkOrderThreshold={storeBulkOrderThreshold}
                  quantityAdjustedmessage={this.props.quantityAdjustedmessage}
                  maxPurchasableStock={maxPurchasableStock}
                  outOfStockStyle={OutOfStockInProductCards}
                  handlingTimeDisplayText={handlingTimeDisplayText}
                  handlingTimeTrackingText={handlingTimeTrackingText}
                  isEnabledStoreHandlingTime={true}
                  checkoutAddress={checkoutAddress}
                />
              </CartCounterWrapper>
              {variant === 'checkout' && (
                <StyledInlineBlock variant={variant}>
                  {variant === 'checkout' && (
                    <Text size="large" weight="black" color="#333333">
                      <Price amount={price} discount={discount} />
                    </Text>
                  )}
                </StyledInlineBlock>
              )}
            </StyledContainer>
          </StyledA>
        </Link>
      </CompactProductWrapper>
    )
  }
}

const CompactProductWrap = props => (
  <GlobalContext.Consumer>
    {({ remoteConfig }) => (
      <CheckoutAddressConsumer>
        {({ checkoutAddress }) => (
          <EventTrackingConsumer>
            {({ track, trackSplit }) => (
              <CartConsumer>
                {({
                  update,
                  includes,
                  countOf,
                  quantityAdjustedmessage,
                  items,
                }) => (
                  <WishlistConsumer>
                    {wishlistConsumer => (
                      <CompactProduct
                        {...props}
                        checkoutAddress={checkoutAddress}
                        track={track}
                        trackSplit={trackSplit}
                        update={update}
                        includes={includes}
                        countOf={countOf}
                        quantityAdjustedmessage={quantityAdjustedmessage}
                        remoteConfig={remoteConfig}
                        cartItems={items}
                        wishlistConsumer={wishlistConsumer}
                      />
                    )}
                  </WishlistConsumer>
                )}
              </CartConsumer>
            )}
          </EventTrackingConsumer>
        )}
      </CheckoutAddressConsumer>
    )}
  </GlobalContext.Consumer>
)

CompactProductWrap.defaultProps = {}

export default CompactProductWrap
