import React, { Component } from 'react'
import _cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import dynamic from 'next/dynamic'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import RecommendedProduct from '../RecommendedProduct/RecommendedProduct'
import { trackingSeeAllClickDY } from '../../utils/DyUtils'
const CountdownTimer = dynamic(() => import('../CountdownTimer/CountdownTimer'))
import { getCountdownConfig } from '../CountdownTimer/utils'
import {
  RecommendedProductWrapper,
  StyledContainer,
  StyledA,
  PlainDiv,
  OfferCardContainer,
  ImageContainer,
} from '../RecommendedProduct/styles'
import CompactProduct from '../CompactProduct/CompactProduct'
import { IdleQueue } from '../../lib/idlize/IdleQueue'
const queue = new IdleQueue()
import {
  CompactProductWrapper,
  StyledA as CompactStyledA,
  StyledContainer as CompactStyledContainer,
  ImageContainer as CompactImageContainer,
} from '../CompactProduct/styles'
import VirtualCarousel from '../VirtualCarousel'
import Loader from '../Loader'
import { generateQueryString } from '../../lib/filterFormatter'
import { get, headers } from '../../lib/fetch'
import getConfig from 'next/config'
import styled from 'styled-components'
import { SCREEN_SIZE } from '../../lib/Media'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider/CheckoutAddressProvider'
import { withRouter } from 'next/router'
const EmptySearchResult = dynamic(() => import('../Error/EmptySearchResult'))
import { AccountConsumer } from '../AccountProvider'
import Text from '../Text/Text'
import {
  RECOMMENDED_FOR_YOU,
  RECOMMENDED_CAT_PAGE,
  RECOMMENDED_CART_PAGE,
  RECOMMENDED_MARKETPLACE,
  SMART_RECOMMENDER_CAT_PAGE,
  PAST_PURCHASE_CART_PAGE,
} from './RecommendationConstants'
import debounce from 'lodash/debounce'
import Link from 'next/link'
import {
  trackingTypes,
  impressionTypes,
  getImpressionData,
  createNewObserver,
  triggerImpressionsTracking,
} from '../../lib/impressionsTracker'
import { trackProductImpressions } from '../../lib/trackClicksAndImpressions'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'
import {
  PAGE_TYPES,
  getNCheckFn as getNCheck,
  GTM_ENV_VAR as GEV,
} from '../../lib/eventTrackerFunction'
import GlobalContext from '../../components/GlobalContext'
import { SwimlaneHeader } from '../../components/ProductCollectionWithBanner/ProductCollectionWithBanner'
const {
  publicRuntimeConfig: { API_URL },
} = getConfig()
import { getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'
import { getUserType } from '../../utils/userType'
import { AMP_EVENT_NAME } from '../../lib/amplitude'
import SprigService from '../../utils/SprigService'
import SwimlaneCountdown from '../SwimlaneCountdown/SwimlaneCountdown'
import {
  initDyParams,
  setDySession,
  getDyFBTExperiments,
} from '../../utils/DyUtils'
import { getGa4ProductListName } from '../../utils/tracking/trackingUtils'

const PRODUCTS_PER_PAGE = 20
const customCollectionCheck = tag => {
  return (
    tag === RECOMMENDED_FOR_YOU ||
    tag === RECOMMENDED_CAT_PAGE ||
    tag === RECOMMENDED_MARKETPLACE ||
    tag === SMART_RECOMMENDER_CAT_PAGE
  )
}

export const ProductCollectionContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1.8125rem;
`
export const ImageSkeleton = styled.div`
  background-color: #eaeaea;
  height: 100%;
  width: 100%;
  &.compact {
    width: 5rem;
  }
`

const CompactTextWrap = styled.div`
  width: 100%;
`

const StyledProductCollection = styled.div`
  padding: 0;
  ${props => props.horizontal && `position: relative;`}
`

export const HorizontalProductCollection = styled.div`
  white-space: normal;
  vertical-align: top;
`

export const RecommendedProductHorizontal = styled(HorizontalProductCollection)`
  > div.product-container {
    width: 11rem;
    height: 23.25rem;
  }

  ${SCREEN_SIZE.From.MobileL} {
    > div.product-container {
      width: 14rem;
      height: 26rem;
    }
  }
`

export const StyledWrapContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  flex: 1;
  text-align: center;
  padding-right: -0.375rem;
  > div {
    width: calc((100% - 1rem) / 2);
  }
  > :nth-child(2n) {
    margin-right: 0rem;
  }

  > div.product-container {
    height: 23.25rem;
  }

  ${SCREEN_SIZE.From.Tablet} {
    > div.product-container {
      height: 26rem;
    }
  }

  ${SCREEN_SIZE.From.Tablet} {
    > div {
      margin: 0 1rem 2rem 0rem;
      width: calc((100% - 2rem) / 3);
    }
    > :nth-child(3n) {
      margin-right: 0rem;
    }

    > :not(:nth-child(3n)) {
      margin-right: 1rem;
    }
  }
  ${SCREEN_SIZE.From.Desktop} {
    margin: 0.25rem 0;
    > div {
      margin: 0 1rem 1rem 0rem;
      width: calc(
        (100% - ${props => (props.withFilterAndSort ? '2rem' : '3rem')}) /
          ${props => (props.withFilterAndSort ? '3' : '4')}
      );
    }
    > :nth-child(${props => (props.withFilterAndSort ? '3n' : '4n')}) {
      margin-right: 0rem;
    }

    > :not(:nth-child(${props => (props.withFilterAndSort ? '3n' : '4n')})) {
      margin-right: 1rem;
    }
  }
  ${SCREEN_SIZE.From.Hd} {
    > div {
      width: calc(
        (100% - ${props => (props.withFilterAndSort ? '3rem' : '4rem')}) /
          ${props => (props.withFilterAndSort ? '4' : '5')}
      );
    }
    > :nth-child(${props => (props.withFilterAndSort ? '4n' : '5n')}) {
      margin-right: 0rem;
    }

    > :not(:nth-child(${props => (props.withFilterAndSort ? '4n' : '5n')})) {
      margin-right: 1rem;
    }
  }
  ${SCREEN_SIZE.From.Uhd} {
    > div {
      width: calc(
        (100% - ${props => (props.withFilterAndSort ? '4rem' : '5rem')}) /
          ${props => (props.withFilterAndSort ? '5' : '6')}
      );
    }
    > :nth-child(${props => (props.withFilterAndSort ? '5n' : '6n')}) {
      margin-right: 0rem;
    }

    > :not(:nth-child(${props => (props.withFilterAndSort ? '5n' : '6n')})) {
      margin-right: 1rem;
    }
  }
`

export const StyledInfo = styled.div`
  position: relative;
  flex-shrink: 0;
  padding: 0.5rem;
  &.skeleton {
    > div {
      margin-bottom: 5px;
      background-color: #eaeaea;
    }
  }
`

export const SmallLine = styled.div`
  width: 70%;
  height: 1.5rem;
  &.compact {
    width: 27%;
  }
`

export const LargeLine = styled.div`
  width: 90%;
  height: 1rem;
`

export const CartSkeleton = styled.div`
  width: 50%;
  margin: 5px auto 0;
  height: 1.875rem;
  background-color: #eaeaea;
  &.compact {
    float: right;
    margin: 0 13% 0 0;
  }
`

const StyledTitle = styled.div`
  display: ${props => (props.showCounter ? 'inline-block' : 'block')};
  font-weight: 700;
  color: #333333;
  font-size: 1.25rem;
  ${SCREEN_SIZE.Below.Tablet} {
    font-size: 1rem;
  }
`
const SubHeader = styled(Text)`
  font-size: 1rem;
  ${SCREEN_SIZE.Below.Tablet} {
    font-size: 0.875rem;
  }
`

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
`

const StyledLoader = styled(Loader)`
  position: fixed;
  left: 50%;
  top: 37%;
`
const HeadingDiv = styled.div`
  margin-bottom: 1rem;
  display: flex;
  position: relative;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  margin-left: auto;
  position: absolute;
  right: 0;
  bottom: 0;
`

export const SkeletonWrapper = styled.div`
  display: flex;
  overflow-x: hidden;
`

const CountdownTimerDesktopWrapper = styled.div`
  display: none;
  ${SCREEN_SIZE.From.MobileL} {
    vertical-align: super;
    display: inline-block;
  }
`

const CountdownTimerMobileWrapper = styled.div`
  display: flex;
  ${SCREEN_SIZE.From.MobileL} {
    display: none;
  }
`

class ProductCollectionWrapper extends Component {
  /* istanbul ignore next */
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      isLoading: false,
      products:
        (this.props.data &&
          this.props.data.collection &&
          this.props.data.collection.product) ||
        [],
      homeProductsToTrack: 0,
      otherProductsToTrack: 0,
      pageViewTracked: '',
      fetching: true,
      trackedImpressions: false,
      noImpressions: false,
      totalPages: 1,
      page: 1,
      hasMore: false,
      attachedScrollListener: false,
      isHidden: false,
      isHomeRecommendationEnabled: true,
      trackedScroll: true,
    }

    if (this.props.config.pageType === PAGE_TYPES.HOME) {
      this.impressionType = impressionTypes.HOMEPAGE_PRODUCT_IMPRESSION
      this.splitImpressionType =
        impressionTypes.SPLIT_HOMEPAGE_PRODUCT_IMPRESSION
    } else {
      this.impressionType = impressionTypes.PRODUCT_IMPRESSION
      this.splitImpressionType = impressionTypes.SPLIT_PRODUCT_IMPRESSION
    }

    this.nodeRef = React.createRef()
    this.fetchAndRoute = this.fetchAndRoute.bind(this)
    this.onScroll = debounce(this.onScroll.bind(this), 250)
    this.handleStoreRef = this.handleStoreRef.bind(this)
    this.createObserver = this.createObserver.bind(this)
    this.handleProductImpressions = this.handleProductImpressions.bind(this)
    this.getRecommendedProductSkeleton =
      this.getRecommendedProductSkeleton.bind(this)
    this.getCompactProductSkeleton = this.getCompactProductSkeleton.bind(this)
    this.setCartRecommendation = this.setCartRecommendation.bind(this)
    this.setPaginationIndexAndTrack = this.setPaginationIndexAndTrack.bind(this)
    this.setPaginationState = this.setPaginationState.bind(this)
    this.isEnabledApiExperiment = this.isEnabledApiExperiment.bind(this)
    this.getMarketplaceRecommendation =
      this.getMarketplaceRecommendation.bind(this)
    this.getCatRecommendation = this.getCatRecommendation.bind(this)
    this.getUserRecommendation = this.getUserRecommendation.bind(this)
    this.getSmartRecommender = this.getSmartRecommender.bind(this)
  }

  isEnabledApiExperiment() {
    const { config } = this.props
    return (
      config?.pageType === 'search' ||
      config?.pageType === 'past-purchase' ||
      config?.pageType === PAGE_TYPES.PROMO ||
      config?.pageType === 'category' ||
      config?.pageType === PAGE_TYPES.TAG ||
      config?.pageType === 'offerlist' ||
      config?.pageType === PAGE_TYPES.BRAND ||
      config?.loc?.includes('ProductListing')
    )
  }

  setPaginationState(params = { resetAll: false }) {
    if (this.props.config?.loadMoreType !== 'SEEALL') {
      let { page, totalPages } = this.state
      if (params?.resetAll) {
        totalPages = 1
        page = 1
      }
      if (this.isEnabledApiExperiment()) {
        if (this.props.data?.collection?.pagination?.total_pages) {
          totalPages = this.props.data?.collection?.pagination?.total_pages
        } else {
          const total = this.props.data?.collection?.count || 0
          if (total >= PRODUCTS_PER_PAGE) {
            totalPages = Math.ceil(total / PRODUCTS_PER_PAGE)
          }
        }
      } else {
        const total = this.props.data?.collection?.count || 0
        if (total >= PRODUCTS_PER_PAGE) {
          totalPages = Math.ceil(total / PRODUCTS_PER_PAGE)
        }
      }

      const hasMore = totalPages > page
      this.setState({ page, totalPages, hasMore })
    }
  }

  /**
   * gets tracking data & sends to track method
   *
   * For Home page, PDP & shopping list as soon as 20 or more products are queued,
   * forceTrack is passed to trigger product impressions
   *
   * For all pages route change or click forceTrack + eventType abandon is passed
   * to make sure that all elements queued before click are tracked for impressions
   *
   */
  handleProductImpressions(
    params = {
      forceTrack: false,
      eventType: undefined,
      thresholdReached: false,
      prevStoreId: '',
    }
  ) {
    const { track, router, trackSplit, config } = this.props
    const products = getImpressionData(this.impressionType)
    if (!products.length) {
      if (!this.state.noImpressions && params.eventType === 'onUpdate') {
        //this flag helps to indentify if tracking method was triggered
        //after that only call createOberserver to update queue as per change triggerred
        this.setState({ noImpressions: true })
      }
      return
    }

    const storeIdToTrack =
      params.prevStoreId ||
      getNCheck(this.props.checkoutAddress, 'clientId', '').toString()
    const searchTermToTrack = router?.query?.query || ''
    let productCardViewProps = {}
    if (config?.metaData?.indexName && products.length > 15) {
      // product_id_zop == DBP_PRODUCT_ID
      const getProductIds = products.length
        ? products.map(item => item.product_id_zop).toString()
        : ''
      productCardViewProps = {
        [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.ALGOLIA_PRODUCT_IMPRESSION,
        [GEV.AMPLITUDE_EVENT_TYPE]: 'view',
        [GEV.AMPLITUDE_SEARCH_QUERY_ID]: config?.metaData?.queryId,
        [GEV.AMPLITUDE_SEARCH_INDEX_NAME]: config?.metaData?.indexName,
        [GEV.AMPLITUDE_EVENT_SCOPE]: 3,
        [GEV.PRODUCT_ID_ZOP]: getProductIds,
      }
    }
    const eventProps = {
      // Added property for Product Impression for Algolia
      [GEV.ALGOLIA_EXP]:
        config?.metaData?.abTestId && config?.metaData?.abTestVariantId
          ? `algolia-${config?.metaData?.abTestId}-${config?.metaData?.abTestVariantId}`
          : undefined,
      ...productCardViewProps,
    }
    trackProductImpressions({
      params,
      currentType: this.impressionType,
      storeIdToTrack,
      searchTermToTrack,
      track,
      trackSplit,
      products,
      remoteConfig: this.props.remoteConfig,
      eventProps,
    })

    if (!this.state.trackedImpressions && params.eventType === 'onUpdate') {
      //this flag helps to indentify if tracking method was triggered
      //after that only call createOberserver to update queue as per change triggerred
      this.setState({ trackedImpressions: true })
    }
  }

  /**
   * Creates observer for all given elements
   * sets minimum product card visibility to 50% for tracking impression
   *
   * default callAction = onLoad
   * if callAction = afterUpdate, then wait for tracking mrthod to trigger first
   */
  createObserver({ productRef, productTrackObj, callAction, splitTrackObj }) {
    if (
      callAction === 'onLoad' ||
      (callAction === 'afterUpdate' &&
        (this.state.trackedImpressions || this.state.noImpressions))
    ) {
      createNewObserver({
        productRef: productRef,
        trackType: trackingTypes.TRACK_ONCE, // signifies tracked once per page load
        productTrackingData: productTrackObj,
        splitTrackingData: splitTrackObj,
        options: {
          root: null,
          rootMargin: '0px',
          threshold: [0.5, 0], // signifies that min 50% of the product card is visible
        },
        impressionType: this.impressionType,
        splitImpressionType: this.splitImpressionType,
      })
    }

    if (callAction === 'afterUpdate') {
      if (this.state.trackedImpressions) {
        this.setState({ trackedImpressions: false })
      } else if (this.state.noImpressions) {
        this.setState({ noImpressions: false })
      }
    }
  }

  setCartRecommendation() {
    this.setState({
      products: this.props.data.collection || [],
    })
  }

  async getMarketplaceRecommendation() {
    const marketplaceRecommendation = (await import('./customWidgetRequests'))
      .default
    const { products, fetching, loading } = await marketplaceRecommendation(
      this.props
    )
    this.setState({
      products: products || [],
      fetching: fetching || false,
      loading: loading || false,
    })
  }

  async getCatRecommendation() {
    const catRecommendation = (
      await import('./recommendation/catRecommendation')
    ).default
    const { products, fetching, loading } = await catRecommendation(this.props)
    this.setState({ products, fetching, loading })
  }

  async getUserRecommendation() {
    const userRecommendation = (
      await import('./recommendation/userRecommendation')
    ).default
    const { products, fetching, loading } = await userRecommendation(this.props)
    this.setState({ products, fetching, loading })
  }

  async getSmartRecommender() {
    if (!this.props.isLoggedIn) {
      this.setState({ fetching, loading })
      return
    }
    const smartRecommender = (await import('./recommendation/smartRecommender'))
      .default
    const { products, fetching, loading } = await smartRecommender(this.props)
    this.setState({ products, fetching, loading })
  }

  componentDidMount() {
    triggerImpressionsTracking.subscribe(this.impressionType, data => {
      if (
        [
          impressionTypes.HOMEPAGE_PRODUCT_IMPRESSION,
          impressionTypes.PRODUCT_IMPRESSION,
        ].includes(data)
      ) {
        queue.pushTask(() => {
          this.handleProductImpressions({ thresholdReached: true })
        })
      }
    })
    // We call userRecommendation when product collection has title Recommended_For_You
    if (this.props.data.title === RECOMMENDED_FOR_YOU) {
      if (!this.state.isHomeRecommendationEnabled) {
        queue.pushTask(() => {
          this.getUserRecommendation()
        })
      } else {
        this.setState({ products: [], fetching: false, loading: false })
      }
    }
    if (this.props.data.title === RECOMMENDED_CAT_PAGE) {
      queue.pushTask(() => {
        this.getCatRecommendation()
      })
    }
    if (
      this.props.data.title === RECOMMENDED_CART_PAGE ||
      this.props.data.title === PAST_PURCHASE_CART_PAGE
    ) {
      queue.pushTask(() => {
        this.setCartRecommendation()
      })
    }

    if (this.props.data.title === RECOMMENDED_MARKETPLACE) {
      queue.pushTask(() => {
        this.getMarketplaceRecommendation()
      })
    }

    if (this.props.data.title === SMART_RECOMMENDER_CAT_PAGE) {
      queue.pushTask(() => {
        this.getSmartRecommender()
      })
    }

    const infiniteScrolling =
      this.props.config.loadMoreType !== 'SEEALL' &&
      this.props.config.layoutType !== 'SCROLLER'

    if (infiniteScrolling && this.nodeRef && this.nodeRef.current) {
      window.addEventListener('scroll', this.onScroll, false)
      this.setState({ attachedScrollListener: true })
    }
    this.setPaginationState({ resetAll: true })
    /**
     * Note: removing this.isHidden causes duplicate APi calls in
     * promotion page. Other PLP could also have some impact.
     * Do not remove without proper solution & testing
     */
    if (
      !this.nodeRef ||
      !this.nodeRef.current ||
      !this.nodeRef.current.offsetHeight
    ) {
      this.setState({ isHidden: true })
    }
    if (!infiniteScrolling) {
      const prods =
        this.state.products &&
        this.state.products.filter(product =>
          product.hasVariants && product.variants[0]
            ? product.variants[0].storeSpecificData
            : product.storeSpecificData
        )
      if (
        prods &&
        prods.length === 0 &&
        this.props.data &&
        this.props.data.collection &&
        this.props.data.collection.count != 0 &&
        this.state.hasMore
      ) {
        this.loadProducts()
      }
    }
  }

  componentWillUnmount() {
    this.handleProductImpressions({ forceTrack: true, eventType: 'abandon' })
    triggerImpressionsTracking.unsubscribe(this.impressionType)

    const infiniteScrolling =
      this.props.config.loadMoreType !== 'SEEALL' &&
      this.props.config.layoutType !== 'SCROLLER'
    if (infiniteScrolling && this.nodeRef && this.nodeRef.current) {
      window.removeEventListener('scroll', this.onScroll, false)
    }
  }

  /**
   * This method resets this.page value when
   * following values in query have changed & sets scroll position to top
   * & tracks product impressions
   * 1. filter
   * 2. sort
   * 3. tag
   * 4. storeId
   * 5. pincode
   *
   * this.page value set in this method is incremented in fetchAndRoute
   * method before making API request
   */
  setPaginationIndexAndTrack = (prevProps, currentProps) => {
    if (
      prevProps.router?.query?.query !== currentProps.router?.query?.query ||
      prevProps.router?.query?.tag !== currentProps.router?.query?.tag ||
      !isEqual(prevProps.data.collection, currentProps.data.collection)
    ) {
      this.handleProductImpressions({ forceTrack: true, eventType: 'onUpdate' })
      this.setPaginationState({ resetAll: true })
    } else if (
      prevProps.checkoutAddress?.storeId !==
      currentProps.checkoutAddress?.storeId
    ) {
      this.handleProductImpressions({
        forceTrack: true,
        eventType: 'onUpdate',
        prevStoreId: getNCheck(
          prevProps.checkoutAddress,
          'clientId',
          ''
        ).toString(),
      })
      this.setPaginationState({ resetAll: true })
      window.scrollTo(0, 0)
    } else if (
      prevProps.router?.query?.slug !== currentProps.router?.query?.slug ||
      prevProps.router?.query?.sorting !==
        currentProps.router?.query?.sorting ||
      prevProps.router?.query?.filter !== currentProps.router?.query?.filter
    ) {
      this.handleProductImpressions({ forceTrack: true, eventType: 'abandon' })
      this.setPaginationState({ resetAll: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.data?.collection?.count &&
      prevProps.data?.collection?.count !== this.props.data?.collection?.count
    ) {
      this.setState({ trackedScroll: true })
    }
    if (
      this.props.data.title &&
      (prevProps.data.title !== this.props.data.title ||
        prevProps.checkoutAddress.storeId !==
          this.props.checkoutAddress.storeId) &&
      customCollectionCheck(this.props?.data?.title)
    ) {
      if (this.props.data.title === RECOMMENDED_FOR_YOU) {
        if (!this.state.isHomeRecommendationEnabled) {
          queue.pushTask(async () => {
            this.getUserRecommendation()
          })
        } else {
          this.setState({ products: [], fetching: false, loading: false })
        }
      }

      if (this.props.data.title === RECOMMENDED_CAT_PAGE) {
        queue.pushTask(() => {
          this.getCatRecommendation()
        })
      }

      if (this.props.data.title === RECOMMENDED_MARKETPLACE) {
        queue.pushTask(() => {
          this.getMarketplaceRecommendation()
        })
      }

      if (this.props.data.title === SMART_RECOMMENDER_CAT_PAGE) {
        queue.pushTask(() => {
          this.getSmartRecommender()
        })
      }
    }

    // we need to reset page value in all of below conditions
    // otherwise all consequent requests will have wrong page
    // also track impressions for the product set before this change
    this.setPaginationIndexAndTrack(prevProps, this.props)

    const infiniteScrolling =
      this.props.config.loadMoreType !== 'SEEALL' &&
      this.props.config.layoutType !== 'SCROLLER'
    if (
      infiniteScrolling &&
      this.nodeRef?.current &&
      !this.state.attachedScrollListener
    ) {
      window.addEventListener('scroll', this.onScroll, false)
      if (this.nodeRef?.current?.offsetHeight) {
        this.setState({ isHidden: false, attachedScrollListener: true })
      } else {
        this.setState({ attachedScrollListener: true })
      }
    }
    if (
      prevProps.data?.collection?.product !==
      this.props.data?.collection?.product
    ) {
      // If product collection name is part of insider recommendation we don't update the data from prop
      if (!customCollectionCheck(this.props.data.title)) {
        const products =
          this.props.data &&
          this.props.data.collection &&
          this.props.data.collection.product
        this.setState({
          products: products,
        })
      }
    } else {
      const lastIndex = prevState.products ? prevState.products.length : 0
      const prods =
        this.state.products &&
        this.state.products
          .slice(lastIndex)
          .filter(product =>
            product.hasVariants && product.variants[0]
              ? product.variants[0].storeSpecificData
              : product.storeSpecificData
          )
      if (
        prods &&
        prods.length === 0 &&
        lastIndex !== this.state.products.length &&
        !this.state.isLoading &&
        this.state.hasMore &&
        infiniteScrolling &&
        this.state.page > 1
      ) {
        this.loadProducts()
      }
    }
  }

  handleStoreRef(node) {
    this.refOfProductListParent = node // storing reference of parent to find the height of product list
  }

  getRecommendedProductSkeleton(value, index) {
    return (
      <RecommendedProductWrapper
        className="product-container"
        key={'product-' + index}
      >
        <StyledA>
          <StyledContainer>
            <PlainDiv>
              <ImageContainer>
                <ImageSkeleton />
              </ImageContainer>
            </PlainDiv>
            <StyledInfo className="skeleton">
              <SmallLine />
              <LargeLine />
              <LargeLine />
            </StyledInfo>
            <OfferCardContainer />
            <CartSkeleton />
          </StyledContainer>
        </StyledA>
      </RecommendedProductWrapper>
    )
  }

  getCompactProductSkeleton(value, index) {
    return (
      <CompactProductWrapper
        className="product-container"
        key={'product-' + index}
      >
        <CompactStyledA>
          <CompactStyledContainer>
            <CompactImageContainer>
              <ImageSkeleton className="compact" />
            </CompactImageContainer>
            <CompactTextWrap>
              <StyledInfo className="skeleton">
                <SmallLine className="compact" />
                <LargeLine />
                <LargeLine />
              </StyledInfo>
              <CartSkeleton className="compact" />
            </CompactTextWrap>
          </CompactStyledContainer>
        </CompactStyledA>
      </CompactProductWrapper>
    )
  }

  onScroll() {
    const {
      state: { error, isLoading, hasMore, trackedScroll },
    } = this

    if (error || isLoading || !hasMore || !this.refOfProductListParent) {
      return
    }

    const productListHeight = this.refOfProductListParent.offsetHeight
    //because IE11 does not support window.scrollY
    const scrollY = window.scrollY || window.pageYOffset
    const threeXWindowHeight = window.innerHeight * 3
    if (scrollY > threeXWindowHeight && trackedScroll) {
      this.setState(
        { trackedScroll: false },
        SprigService.track('grocery_online_search_result_scrolldepth_thirdfold')
      )
    }
    const threshold = 4000
    if (productListHeight <= scrollY + threshold) {
      this.setState({ isLoading: true }, this.loadProducts)
    }
  }

  loadProducts(replaceData) {
    this.handleProductImpressions({ forceTrack: false, eventType: 'paginate' })
    if (this.state.isHidden) {
      return
    }
    if (replaceData) {
      this.setState({ sortFilterLoading: true, page: 1 }, () => {
        this.fetchAndRoute(replaceData)
      })
    } else {
      this.fetchAndRoute(replaceData)
    }
  }

  async fetchAndRoute(replaceData) {
    const { source } = this.props
    const { products, totalPages } = this.state
    const currentProducts = _cloneDeep(products)

    // eslint-disable-next-line react/no-direct-mutation-state
    const page = ++this.state.page
    if (page <= totalPages) {
      this.setState({ page: page })
      const userKey = window?.__NEXT_DATA__?.props?.userKey
      const url = 'product/v2'
      /* istanbul ignore next */
      const { ...configData } = this.props.config
      const additionalQuery = {
        ...configData,
        ...this.props.router.query,
      }
      if (this.isEnabledApiExperiment() && this.props.getTestVersions) {
        const runningExperiments = this.props.getTestVersions()
        if (runningExperiments.length) {
          // Added Algopers params
          const { configuration } = getConfigFor(
            SPLIT_FEATURES.ALGOLIA_PERSONALISATION
          )
          const { personalisationSettings } = configuration
          additionalQuery.algopers = personalisationSettings
          const fbtExperiments = getDyFBTExperiments(true)
          additionalQuery.experiments =
            fbtExperiments !== 'undefined'
              ? `${runningExperiments},${fbtExperiments}`
              : runningExperiments
        }
      }
      if (source) {
        additionalQuery.source = source
      }
      const storeId =
        this.props.checkoutAddress && this.props.checkoutAddress.storeId
      /* istanbul ignore next */
      const orderType = getUserType() === 'B2B' ? 'B2B' : 'DELIVERY'

      // query params can be modified from props
      const queryString = this.props.transformConfig
        ? this.props.transformConfig({
            ...additionalQuery,
            page,
            storeId,
            orderType,
          })
        : generateQueryString(
            {
              ...additionalQuery,
              page,
              storeId,
              orderType,
            },
            true
          )

      try {
        // DY
        const dyParams = initDyParams()
        let api = `${API_URL}/${url}${queryString}${dyParams}`
        api +=
          api.indexOf('?') > -1
            ? '&includeTagDetails=true'
            : '?includeTagDetails=true'

        const resp = await get(api, {
          headers: {
            ...headers(),
            'x-device-Id': userKey,
          },
        })
        const metaData = resp?.data?.metaData
        setDySession(metaData)
        const results = resp.data
        const nextProducts = _cloneDeep(results.product)

        if (nextProducts && nextProducts.length > 0) {
          this.setState({
            isLoading: false,
            sortFilterLoading: false,
            products: !replaceData
              ? [...currentProducts, ...nextProducts]
              : nextProducts,
          })
          this.setPaginationState()
        } else {
          this.setState({
            hasMore: false,
            isLoading: false,
            sortFilterLoading: false,
            products: !replaceData ? currentProducts : null,
          })
        }
      } catch (error) {
        this.setState({
          hasMore: false,
          isLoading: false,
          sortFilterLoading: false,
        })
      }
    } else {
      this.setState({
        isLoading: false,
        sortFilterLoading: false,
      })
      this.setPaginationState()
    }
  }

  handleTrackSeeAllClick = () => {
    const { track, config } = this.props
    const eventData = {
      [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.WIDGET_SEE_ALL_CLICKED,
      [GEV.AMPLITUDE_CTA_SUBLOCATION]: config?.ctaSublocation,
      [GEV.AMPLITUDE_CTA_LOCATION]: this.props.ctaLocation,
    }
    track(eventData)
    const dyPayload = trackingSeeAllClickDY(config?.metaData)
    track(dyPayload)
  }

  render() {
    const { error, isLoading, products, fetching } = this.state
    const {
      type,
      config,
      hideSeeAllButton,
      data,
      router,
      ctaLocation,
      preLoadFirstImage,
      isLoading: _isLoadingProps,
    } = this.props
    let { subtitle, pageType, loc, ctaSublocation } = config
    let titleText = data?.title
    if (titleText === RECOMMENDED_FOR_YOU) {
      titleText = 'Recommended For You'
    } else if (titleText === RECOMMENDED_CAT_PAGE) {
      titleText = 'Recommended For You' || 'Recommended For Category section'
      /*
        This is a work arround to implement POC for cross category recommendation.
        Subtitle will represent cross category name which will be hidden in UI.
      */
      subtitle = ''
    } else if (
      titleText === RECOMMENDED_CART_PAGE ||
      titleText === RECOMMENDED_MARKETPLACE
    ) {
      titleText = 'Recommended For You'
    } else if (titleText === SMART_RECOMMENDER_CAT_PAGE) {
      titleText = 'Special Text'
    }
    if (config.personalisationApi) {
      config.pageType = config.personalisationApi
    }
    // This variable to check whether we need to show the skeleton.
    let shouldLoad = false
    if (
      _isLoadingProps ||
      isLoading ||
      (customCollectionCheck(data?.title) && fetching)
    ) {
      shouldLoad = true
    }
    const productList =
      products &&
      products.filter(product =>
        product.hasVariants && product.variants[0]
          ? product.variants[0].storeSpecificData
          : product.storeSpecificData
      )

    //Set config values for pageType & url only when
    // 'tag', 'category', 'brand', 'past-purchase' is not set
    const queryString = {}
    const overrideParams = ['pageType', 'url', 'layoutType']
    for (const key in config) {
      if (!overrideParams.includes(key) && config[key]) {
        queryString[key] = encodeURIComponent(config[key])
      }
      if (['tag', 'category', 'brand', 'past-purchase'].includes(key)) {
        queryString.pageType = key
        queryString.url = config[key]
      }
    }
    //overrideParams can be set after evaluating above condition
    overrideParams.forEach(overrideParam => {
      if (
        !queryString[overrideParam] &&
        !!config[overrideParam] &&
        overrideParam !== 'layoutType'
      ) {
        queryString[overrideParam] = config[overrideParam]
      }
    })

    const countdownConfig = getCountdownConfig()
    const asPath = this.props && this.props.router && this.props.router.asPath
    let productsContainer = ''

    const productListName = loc ? loc : pageType
    const that = this

    const ga4ProductListName = getGa4ProductListName(config, router)

    if (config.loadMoreType === 'SEEALL') {
      const WrapperComponent =
        config.layoutType === 'SCROLLER'
          ? HorizontalProductCollection
          : RecommendedProductHorizontal
      const Product =
        config.layoutType === 'SCROLLER' ? CompactProduct : RecommendedProduct
      if (shouldLoad) {
        productsContainer =
          config.layoutType === 'SCROLLER' ? (
            <SkeletonWrapper>
              {[...Array(20).keys()].map((item, index) => (
                <WrapperComponent key={'wrap-comp-' + item}>
                  {that.getCompactProductSkeleton(item, index)}
                </WrapperComponent>
              ))}
            </SkeletonWrapper>
          ) : (
            <SkeletonWrapper>
              {[...Array(20).keys()].map((item, index) => (
                <WrapperComponent key={'wrap-' + item}>
                  {that.getRecommendedProductSkeleton(item, index)}
                </WrapperComponent>
              ))}
            </SkeletonWrapper>
          )
      } else {
        const productProps = {
          cart: this.props.cart,
          loc: loc,
          ctaSublocation: ctaSublocation,
          ctaLocation: ctaLocation,
          pageType: pageType,
          updateIsAddressPresent: this.props.updateIsAddressPresent,
          onSave: val => val,
          isLoggedIn: this.props.isLoggedIn,
          asPath: asPath,
          productListName: productListName,
          ga4ProductListName,
          createObserver: this.createObserver,
          onProductImpressions: this.handleProductImpressions,
          impressionType: this.impressionType,
          searchTerm: router?.query?.query || '',
          metaData: config.metaData,
        }

        const staticHeight = this.props.withBanner
          ? {
              [SCREEN_SIZE.From.MobileL]:
                config.layoutType === 'SCROLLER' ? 174 : 421,
              [SCREEN_SIZE.Below.MobileL]:
                config.layoutType === 'SCROLLER' ? 174 : 377,
            }
          : {
              [SCREEN_SIZE.From.MobileL]:
                config.layoutType === 'SCROLLER' ? 174 : 420,
              [SCREEN_SIZE.Below.MobileL]:
                config.layoutType === 'SCROLLER' ? 174 : 372,
            }
        productsContainer = (
          <>
            {this.props.withBanner && (
              <SwimlaneHeader
                title={titleText}
                subtitle={subtitle}
                // ProductCollection with swimlane only supports
                // navigating to tag detail pages on see all click.
                // We will have to add support for category pages if needed.
                seeAllHref={`/tag/${config?.tag}`}
                onSeeAllClick={this.handleTrackSeeAllClick}
              />
            )}
            <VirtualCarousel
              items={productList}
              staticHeight={staticHeight}
              staticWidth={{
                [SCREEN_SIZE.From.MobileL]:
                  config.layoutType === 'SCROLLER' ? 360 : 240,
                [SCREEN_SIZE.Below.MobileL]:
                  config.layoutType === 'SCROLLER' ? 328 : 192,
              }}
              withBanner={this.props.withBanner}
            >
              {({ item, index }) => (
                <WrapperComponent role="cell">
                  <Product
                    {...productProps}
                    details={item}
                    productPosition={index + 1}
                  />
                </WrapperComponent>
              )}
            </VirtualCarousel>
          </>
        )

        if (this.props.withBanner) {
          if (!this.state.products.length) {
            return
          }
          return productsContainer
        }
      }
    } else {
      const Product = RecommendedProduct
      productsContainer = (
        <div ref={this.handleStoreRef}>
          <StyledWrapContainer withFilterAndSort={this.props.withFilterAndSort}>
            {productList &&
              productList.map((product, index) => (
                <Product
                  role="cell"
                  key={'product-' + index}
                  loc={loc}
                  ctaSublocation={ctaSublocation}
                  ctaLocation={ctaLocation}
                  pageType={pageType}
                  cart={this.props.cart}
                  preLoadFirstImage={preLoadFirstImage}
                  updateIsAddressPresent={this.props.updateIsAddressPresent}
                  details={product}
                  onSave={val => val}
                  isLoggedIn={this.props.isLoggedIn}
                  asPath={asPath}
                  productPosition={index + 1}
                  productListName={productListName}
                  ga4ProductListName={ga4ProductListName}
                  createObserver={this.createObserver}
                  onProductImpressions={this.handleProductImpressions}
                  onHandleNotificationClosedByTimeOut={
                    this.props.onHandleNotificationClosedByTimeOut
                  }
                  impressionType={this.impressionType}
                  searchTerm={router?.query?.query || ''}
                  metaData={config.metaData}
                />
              ))}
          </StyledWrapContainer>
          {error && <div>{error}</div>}
          {shouldLoad && (
            <StyledWrapContainer
              withFilterAndSort={this.props.withFilterAndSort}
            >
              {[...Array(20).keys()].map((value, index) =>
                this.getRecommendedProductSkeleton(value, index)
              )}
            </StyledWrapContainer>
          )}
        </div>
      )
    }
    const queryStringInfo = `/product-listing${generateQueryString(
      queryString
    )}`
    return (!productList || productList.length === 0) &&
      !(customCollectionCheck(this.props.data.title) && fetching) &&
      Object.keys(this.props.filter || {}).length === 0 &&
      !_isLoadingProps ? null : (
      <ProductCollectionContainer
        className="productCollection"
        data-testid="productCollection"
        id="productCollection"
      >
        {this.props.filters &&
        Object.keys(this.props.filters).length > 0 &&
        productList &&
        productList.length === 0 ? (
          <EmptySearchResult noLayout />
        ) : (
          <StyledProductCollection
            horizontal={this.props.config.loadMoreType === 'SEEALL'}
            compType={type}
            data-testid="product-collection"
            ref={this.nodeRef}
          >
            {this.props.data &&
              this.props.data.collection &&
              this.props.data.title && (
                <HeadingDiv>
                  <div>
                    {countdownConfig.showCounter &&
                      countdownConfig.swimlaneTitle === titleText && (
                        <CountdownTimerMobileWrapper>
                          <CountdownTimer countdownConfig={countdownConfig} />
                        </CountdownTimerMobileWrapper>
                      )}
                    {titleText && (
                      <StyledTitle
                        as="h2"
                        size="large"
                        weight="bold"
                        color={defaultTheme.ColorBaseMineShaft700}
                        title={titleText}
                        showCounter={countdownConfig.showCounter}
                      >
                        {titleText}
                      </StyledTitle>
                    )}
                    {countdownConfig.showCounter &&
                      countdownConfig.swimlaneTitle === titleText && (
                        <CountdownTimerDesktopWrapper>
                          <CountdownTimer countdownConfig={countdownConfig} />
                        </CountdownTimerDesktopWrapper>
                      )}
                    {subtitle && (
                      <SubHeader
                        as="h3"
                        color={defaultTheme.ColorBaseMineShaft500}
                        size="large"
                      >
                        {subtitle}
                      </SubHeader>
                    )}
                  </div>
                  {this.props.config.loadMoreType === 'SEEALL' &&
                    !customCollectionCheck(data.title) &&
                    !hideSeeAllButton && (
                      <Link
                        href={queryStringInfo}
                        passHref
                        prefetch={false}
                        legacyBehavior
                      >
                        <StyledLink
                          as="a"
                          size="medium"
                          color={defaultTheme.ColorBaseBrandPrimary500}
                          weight="bold"
                          onClick={this.handleTrackSeeAllClick}
                        >
                          See all
                        </StyledLink>
                      </Link>
                    )}
                </HeadingDiv>
              )}
            <Link href={queryStringInfo} style={{ textDecoration: 'none' }}>
              <SwimlaneCountdown swimlaneTitle={titleText} />
            </Link>
            {productsContainer}
          </StyledProductCollection>
        )}
        {this.state.sortFilterLoading && (
          <LoaderContainer>
            <StyledLoader />
          </LoaderContainer>
        )}
      </ProductCollectionContainer>
    )
  }
}

const ProductCollection = props => (
  <GlobalContext.Consumer>
    {({ remoteConfig, experiments }) => (
      <AccountConsumer>
        {({ isLoggedIn, login, accountData }) => (
          <CheckoutAddressConsumer>
            {({ checkoutAddress }) => (
              <EventTrackingConsumer>
                {({ track, getTestVersions, trackSplit }) => (
                  <React.Fragment>
                    <div />
                    {/* Needed for avoiding layout shifting in the home page */}
                    <ProductCollectionWrapper
                      checkoutAddress={checkoutAddress}
                      {...props}
                      isLoggedIn={isLoggedIn}
                      login={login}
                      track={track}
                      trackSplit={trackSplit}
                      remoteConfig={remoteConfig}
                      experiments={experiments}
                      getTestVersions={getTestVersions}
                      accountData={accountData}
                    />
                  </React.Fragment>
                )}
              </EventTrackingConsumer>
            )}
          </CheckoutAddressConsumer>
        )}
      </AccountConsumer>
    )}
  </GlobalContext.Consumer>
)

ProductCollection.defaultProps = {
  config: {
    layoutType: 'SCROLLER',
    loadMoreType: 'SEEALL',
    loc: 'ProductWidget-SimilarProducts',
  },
  hideSeeAllButton: false,
  withFilterAndSort: false,
}

export default withRouter(ProductCollection)
